import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import section2 from "./assets/section2.webp"
import video from './assets/landing_clip.mp4'

import dhcb from "./assets/dhcb.jpeg"
import tufts from "./assets/tufts.png"

import ContactForm from "./components/Contact/ConatctForm"
import { Directions, LinkedIn } from '@mui/icons-material';
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";

function goToQ() {
    window.open('https://q.cureva.co', '_blank'); // Replace with your desired URL
}
function goToClinicalMatch() {
    window.open('https://clinicalmatch.cureva.co', '_blank'); // Replace with your desired URL
}


function App() {
    const handleEnded = (event) => {
        setTimeout(() => {
          event.target.play();
        }, 3000); // 5-second delay
      };
  return (
    <div className="App">
      <ScrollToHashElement behavior="smooth" inline="center" block="center"/>
      <Header/>
      <div className="p-10">
        <section>
          <h2 className="text-gray-500 text-4xl">Transforming Clinical Trials Recruitment using AI</h2>
          <h4 className="text-black mt-5 text-3xl">Our team is committed to making patient recruitment a problem of the past</h4>
          <a href="#services"><button className="bg-weird-yellow text-white py-3 px-8 font-expanded font-medium text-xl mt-6  rounded-full">Explore our solutions</button></a>
        </section>
        <section>
        <video className="w-full mt-24"  autoPlay muted
          loop={false} // Disable loop attribute since we handle it manually
          onEnded={handleEnded} // Call handleEnded when video ends
        >
            <source src={video} type="video/mp4"/>
        </video>
        </section>
        <section className="mt-24 w-full text-center">
          <p className="md:text-lg text-xl">Welcome on board</p>
          <p className="text-[#5e5e5e] mt-4 leading-loose"> We are a service committed to optimizing the clinical trials recruitment process. We connect pharmas, CROs,<br/> hospital systems, and patients to accelerate research initiatives and optimize patient outcomes.</p>
          <a href="#contact"><button className="bg-white border border-puke hover:bg-weird-yellow text-puke hover:text-white py-3 px-8 font-expanded font-medium text-xl mt-6 rounded-full">Request a demo</button></a>
        </section>
        <section id="services" className='text-center md:flex-row gap-12 mx-auto md:mx-12 mt-24'>


          {/* <button className="rounded-lg md:mx-0 mx-auto text-3xl  text-weird-yellow  bg-white w-full md:w-1/3 h-80" disabled>
            Coming soon
          </button> */}
          <button className="rounded-lg transition duration-500 ease-in-out hover:text-2xl md:mx-0 mx-auto text-3xl  text-white bg-weird-yellow  w-full md:w-1/3 h-80" onClick={goToClinicalMatch}>
            Integration API
          </button>
          {/* <button className="rounded-lg md:mx-0 mx-auto text-3xl  text-weird-yellow  bg-white w-full md:w-1/3 h-80" disabled>
            Coming soon
          </button> */}
          {/* <button className="rounded-lg transition duration-500 ease-in-out hover:text-2xl md:mx-0 mx-auto text-3xl w-full md:w-1/3 text-white bg-weird-yellow h-80" onClick={goToQ}>
            The Q
          </button> */}
        </section>
        {/* <section id="partners" className="text-center flex flex-col mt-14">
          <p className="text-xl mt-20 mb-12">Our partners</p>
          <div className="flex flex-row justify-around">
            <img className="w-50 h-28 mr-5" src={dhcb} alt="dhcb"/> */}
            {/* <img className="w-50 h-28 mr-5" src={tufts} alt="dhcb"/> */}
          {/* </div>
        </section> */}
        {/* <section className="mt-24 w-full text-center"> */}
          {/* <p className="md:text-lg text-xl">Discover Cureva</p> */}
          {/* <p className="text-[#5e5e5e] mt-4 leading-loose"> We are passionate about creating software products that help reshape today's world.<br/> Our team of experienced doctors and engineers is dedicated to delivering customized solutions that <br/>challenges convention. We believe there are a lot of tech out there that's lacking usecases.<br/> We inherently believe healthcare is a space with a lot of deficiencies. Our team is on a mission to enable tech<br/> to solve these problems. Starting with the biggest clinical trials patient recruitment.</p> */}
          {/* <a href="#contact"><button className="bg-white border border-puke hover:bg-weird-yellow text-puke hover:text-white py-3 px-8 font-expanded font-medium text-xl mt-6 rounded-full">Contact us</button></a> */}
        {/* </section> */}
        <section id="contact" className="text-center flex flex-col">
          <p className="text-center text-2xl font-medium mt-12 md:mt-24">Contact Us</p>
          <ContactForm/>
        </section>
        <section className="my-12 w-full px-2 md:px-20">
          <a rel="noreferrer" href="https://www.google.com/maps/dir//Boston,+MA+02113,+USA/@42.3653936,-71.1373776,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e3708939aab979:0xedffe7e92e98ae87!2m2!1d-71.0549768!2d42.3654231?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D" target="_blank"><button className="absolute right-16 md:right-32 bg-weird-yellow hover:bg-yellow-500 text-black py-2 px-4 md:px-8 font-expanded font-medium text-sm my-auto md:text-xl mt-6 rounded-full "><Directions/> Get Directions</button></a>
          <iframe title="map" className="w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6018.789914809422!2d-71.05995730397356!3d42.36429248476977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3708939aab979%3A0xedffe7e92e98ae87!2sBoston%2C%20MA%2002113%2C%20USA!5e1!3m2!1sen!2seg!4v1727452376619!5m2!1sen!2seg" width="600" height="450" loading="lazy"></iframe>
        </section>
        <section className="flex flex-col">
          <p className="text-center text-2xl mb-12 font-medium">Social</p>
          <a href="https://www.linkedin.com/company/cureva-co" target="_blank" rel="noopener noreferrer">
          <LinkedIn className="text-center text-[#0072b1] w-44 h-32 mx-auto w-full"/>
          </a>
        </section>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
