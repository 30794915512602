import logo from '../../assets/logo.webp';
import React from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";

const Header = () => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box sx={{ width: 400 }} role="presentation" onClick={toggleDrawer(false)}>
            <ScrollToHashElement behavior="smooth" inline="center" block="center" />
            <div className="p-14 flex flex-col gap-4 text-xl">
                <div class="w-full flex flex-row-reverse">
                    <CloseIcon className="hover:text-weird-yellow hover:cursor-pointer"/>
                </div>
                <ListItem>
                    <div>
                        <a href="/" className="font-bold border-b border-weird-yellow hover:cursor-pointer">Home</a>
                    </div>
                </ListItem>
                <ListItem>
                        <a href="#services" className="hover:font-bold hover:border-b hover:border-weird-yellow hover:cursor-pointer">Services</a>
                </ListItem>
                <ListItem>
                        <a href="#contact" className="hover:font-bold hover:border-b hover:border-weird-yellow hover:cursor-pointer">Contact</a>
                </ListItem>
                <ListItem>
                        <a href="#partners" className="hover:font-bold hover:border-b hover:border-weird-yellow hover:cursor-pointer">Partners</a>
                </ListItem>
                <ListItem>
                        <a href="#services" className="hover:font-bold hover:border-b hover:border-weird-yellow hover:cursor-pointer">API</a>
                </ListItem>
            </div>
        </Box>
    );

    return (
        <div className="flex flex-row justify-between px-12 p-4 sticky top-0 bg-white w-full z-20">
            <img className="w-36" src={logo} alt="logo" />
            <button onClick={toggleDrawer(true)}><MenuIcon /></button>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    )
}

export default Header
